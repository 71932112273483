<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <h3 class="fw-bolder m-0 text-white">Cari Wilayah</h3>
        <div
          class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-50
          "
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Pilih Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Pilih Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <div
              class="card bg-white rounded position-absolute ms-5 mt-10"
              style="z-index: 2"
            >
              <div class="card-body border-top p-5">
                <div class="fs-5 fw-bolder">Keterangan :</div>
                <ul class="list-unstyled mb-0">
                  <li v-for="(item, i) in info" :key="i" class="m-0 p-0">
                    <img class="me-1" :src="item.img" width="40" />
                    <span class="fs-8"> {{ item.text }} </span>
                  </li>
                </ul>
              </div>
            </div>
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>

            <template v-for="item in markers" :key="item.territory.id">
              <!-- icon -->
              <l-marker
                :lat-lng="[item.territory.latitude, item.territory.longitude]"
              >
                <l-icon
                  :icon-url="
                    item.ages[0].count > item.ages[1].count
                      ? info[0].img
                      : item.ages[1].count > item.ages[2].count
                      ? info[1].img
                      : info[2].img
                  "
                  :icon-size="iconSize"
                />
                <l-tooltip>
                  <div class="fs-4 fw-bolder mb-1">
                    {{ item.territory.name }}
                  </div>
                  <ul class="list-unstyled mb-0">
                    <li>
                      Pemuda :
                      {{ Intl.NumberFormat().format(item.ages[0].count) }}
                    </li>
                    <li>
                      Dewasa :
                      {{ Intl.NumberFormat().format(item.ages[1].count) }}
                    </li>
                    <li>
                      Orang Tua :
                      {{ Intl.NumberFormat().format(item.ages[2].count) }}
                    </li>
                  </ul>
                </l-tooltip>
              </l-marker>
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
  },
  setup() {
    setCurrentPageTitle("Peta Zona");

    const store = useStore();
    // const router = useRouter();
    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllZoningDashboard", {range_age_id: 0 });
    // store.dispatch

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const markers = computed(() => store.state.ZoningDashboardModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
    });

    const info = ref([
      {
        img: "other/png/pemuda.png",
        text: "Dominasi Pemuda",
      },
      {
        img: "other/png/pemuda-2.png",
        text: "Dominasi Dewasa",
      },
      {
        img: "other/png/pemuda-1.png",
        text: "Dominasi Orang Tua",
      },
    ]);

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kecamatan);
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllZoningDashboard", {
        range_age_id: 0,
        territory_id: wilayah,
      });
    };
    const updateTanpaWilayah = () => {
      store.dispatch("AllZoningDashboard", {
        range_age_id: 0,
      });
    };

    return {
      center,
      markers,
      info,
      zoom,
      iconSize,
      form,
      kecamatan,
      kelurahan,
      getKecamatan,
      getKelurahan,
    };
  },
});
</script>
