
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
  },
  setup() {
    setCurrentPageTitle("Peta Zona");

    const store = useStore();
    // const router = useRouter();
    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllZoningDashboard", {range_age_id: 0 });
    // store.dispatch

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const markers = computed(() => store.state.ZoningDashboardModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
    });

    const info = ref([
      {
        img: "other/png/pemuda.png",
        text: "Dominasi Pemuda",
      },
      {
        img: "other/png/pemuda-2.png",
        text: "Dominasi Dewasa",
      },
      {
        img: "other/png/pemuda-1.png",
        text: "Dominasi Orang Tua",
      },
    ]);

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kecamatan);
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllZoningDashboard", {
        range_age_id: 0,
        territory_id: wilayah,
      });
    };
    const updateTanpaWilayah = () => {
      store.dispatch("AllZoningDashboard", {
        range_age_id: 0,
      });
    };

    return {
      center,
      markers,
      info,
      zoom,
      iconSize,
      form,
      kecamatan,
      kelurahan,
      getKecamatan,
      getKelurahan,
    };
  },
});
